import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../Css/CheckoutForm.css';
import Header from './Header';

const loadRazorpayScript = () => {
  return new Promise((resolve) => {
    const script = document.createElement('script');
    script.src = 'https://checkout.razorpay.com/v1/checkout.js';
    script.onload = () => resolve(true);
    script.onerror = () => resolve(false);
    document.body.appendChild(script);
  });
};

const displayRazorpay = async (orderData) => {
  const res = await loadRazorpayScript();

  if (!res) {
    alert('Razorpay SDK failed to load. Are you online?');
    return;
  }

  const options = {
    key: 'YOUR_RAZORPAY_KEY',
    amount: orderData.amount,
    currency: 'INR',
    name: 'Your Company Name',
    description: 'Test Transaction',
    image: 'https://example.com/your_logo',
    order_id: orderData.id,
    handler: function (response) {
      alert(`Payment ID: ${response.razorpay_payment_id}`);
      alert(`Order ID: ${response.razorpay_order_id}`);
      alert(`Signature: ${response.razorpay_signature}`);
    },
    prefill: {
      name: orderData.name,
      email: orderData.email,
    },
    theme: {
      color: '#3399cc',
    },
  };

  const paymentObject = new window.Razorpay(options);
  paymentObject.open();
};

const CheckoutForm = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    companyName: '',
    country: 'India',
    streetAddress: '',
    apartment: '',
    city: '',
    state: '',
    pinCode: '',
    phone: '',
    email: '',
    paymentMethod: 'COD',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (formData.paymentMethod === 'Razorpay') {
      const orderData = {
        amount: 10000, // Amount in paise (e.g., 10000 paise = 100 INR)
        name: `${formData.firstName} ${formData.lastName}`,
        email: formData.email,
      };
      await displayRazorpay(orderData);
    } else {
      alert('Order placed successfully!');
      navigate('/order-confirmation');
    }
  };

  return (
    <>
    <Header/>
    <div className="woo-checkout-container">
      <div className="woo-billing-details">
        <h2>Billing details</h2>
        <form onSubmit={handleSubmit}>
          <div className="woo-form-group">
            <label>First name *</label>
            <input
              type="text"
              name="firstName"
              value={formData.firstName}
              onChange={handleChange}
              required
            />
          </div>
          <div className="woo-form-group">
            <label>Last name *</label>
            <input
              type="text"
              name="lastName"
              value={formData.lastName}
              onChange={handleChange}
              required
            />
          </div>
          <div className="woo-form-group">
            <label>Company name (optional)</label>
            <input
              type="text"
              name="companyName"
              value={formData.companyName}
              onChange={handleChange}
            />
          </div>
          <div className="woo-form-group">
            <label>Country/Region *</label>
            <select
              name="country"
              value={formData.country}
              onChange={handleChange}
              required
            >
              <option value="India">India</option>
              {/* Add more countries as needed */}
            </select>
          </div>
          <div className="woo-form-group">
            <label>Street address *</label>
            <input
              type="text"
              name="streetAddress"
              value={formData.streetAddress}
              onChange={handleChange}
              required
            />
            <input
              type="text"
              name="apartment"
              value={formData.apartment}
              onChange={handleChange}
              placeholder="Apartment, suite, unit, etc. (optional)"
            />
          </div>
          <div className="woo-form-group">
            <label>Town / City *</label>
            <input
              type="text"
              name="city"
              value={formData.city}
              onChange={handleChange}
              required
            />
          </div>
          <div className="woo-form-group">
            <label>State *</label>
            <input
              type="text"
              name="state"
              value={formData.state}
              onChange={handleChange}
              required
            />
          </div>
          <div className="woo-form-group">
            <label>PIN Code *</label>
            <input
              type="text"
              name="pinCode"
              value={formData.pinCode}
              onChange={handleChange}
              required
            />
          </div>
          <div className="woo-form-group">
            <label>Phone *</label>
            <input
              type="tel"
              name="phone"
              value={formData.phone}
              onChange={handleChange}
              required
            />
          </div>
          <div className="woo-form-group">
            <label>Email address *</label>
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
            />
          </div>
          {/* <div className="woo-terms-and-conditions">
            <input type="checkbox" required />
            <label>
              I have read and agree to the website terms and conditions *
            </label>
          </div> */}
        </form>
      </div>
      <div>
        <div className="woo-order-summary">
          <h2>Your order</h2>
          <table>
            <thead>
              <tr>
                <th>Product</th>
                <th>Subtotal</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Slim Fit Dress Shirts × 1</td>
                <td>₹50.00</td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <th>Subtotal</th>
                <td>₹50.00</td>
              </tr>
              <tr>
                <th>Shipping</th>
                <td>Flat rate: ₹50.00</td>
              </tr>
              <tr className="woo-total">
                <th>Total</th>
                <td>₹100.00</td>
              </tr>
            </tfoot>
          </table>
        </div>
        <div className="woo-payment-section">
          <h2>Payment</h2>
          <div className="woo-payment-methods">
            <div>
              <input
                type="radio"
                name="paymentMethod"
                value="COD"
                checked={formData.paymentMethod === 'COD'}
                onChange={handleChange}
              />
              <label>Cash on delivery</label>
            </div>
            <div>
              <input
                type="radio"
                name="paymentMethod"
                value="Razorpay"
                checked={formData.paymentMethod === 'Razorpay'}
                onChange={handleChange}
              />
              <label>
                UPI/Credit Card/Debit Card/NetBanking - Pay by Razorpay
              </label>
            </div>
          </div>
          <button
            type="button"
            className="woo-place-order-button"
            onClick={handleSubmit}
          >
            Place order
          </button>
        </div>
      </div>
    </div>
    </>
  );
};

export default CheckoutForm;
