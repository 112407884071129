import React, { useEffect, useState } from 'react';
import '../Css/BookingPage.css';
import Header from './Header';
import Footer from './Footer';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';

export default function BookingPage() {
  const { id } = useParams();
  const [selectedPackage, setSelectedPackage] = useState('Starter');
  const [selectedCity, setSelectedCity] = useState('Delhi NCR');
  const [poojaImage, setPoojaImage] = useState('');
  const [poojaMessage, setPoojaMessage] = useState('');
  const [poojaName, setPoojaName] = useState('');
  const [features, setFeatures] = useState([]);
  const [startFeatures, setStartFeatures] = useState([]);
  const [standardFeatures, setStandardFeatures] = useState([]);
  const [premiumFeatures, setPremiumFeatures] = useState([]);
  const [packagePrices, setPackagePrices] = useState({
    Starter: '₹5000',
    Standard: '₹8000',
    Premium: '₹12000'
  });
  const navigate = useNavigate();

  const fetchPooja = async () => {
    try {
      const { data } = await axios.get(`https://free.jeevansanskriti.com/api/v1/pooja/${id}`);
      const poojaData = data.data[0]; // Assuming the first item is needed
      
      setPoojaImage(poojaData.image);
      setPoojaMessage(poojaData.message);
      setPoojaName(poojaData.pooja_name);
      setFeatures(poojaData.features.split(","));

      const starterTier = poojaData.price_tiers.find(tier => tier.tier_name === "Starter");
      const standardTier = poojaData.price_tiers.find(tier => tier.tier_name === "Standard");
      const premiumTier = poojaData.price_tiers.find(tier => tier.tier_name === "Premium");

      setPackagePrices({
        Starter: starterTier ? starterTier.price : "Price not available",
        Standard: standardTier ? standardTier.price : "Price not available",
        Premium: premiumTier ? premiumTier.price : "Price not available"
      });

      setStartFeatures(starterTier ? starterTier.features.split(",") : ['No features available']);
      setStandardFeatures(standardTier ? standardTier.features.split(",") : ['No features available']);
      setPremiumFeatures(premiumTier ? premiumTier.features.split(",") : ['No features available']);
    } catch (error) {
      console.error("Error fetching pooja data:", error);
    }
  };

  useEffect(() => {
    fetchPooja();
  }, [id]);

  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  const handlePackageClick = (packageName) => {
    setSelectedPackage(packageName);
  };

  const handleCityClick = (cityName) => {
    setSelectedCity(cityName);
  };

  const handleBookNow = () => {
    navigate('/puja-final_checkout', {
      state: {
        poojaName,
        productId: id,
        selectedPackage,
        packagePrice: packagePrices[selectedPackage],
        selectedCity,
        poojaImage
      }
    });
  };
 
  return (
    <>
      <Header />
      <div className="containerTR">
        <div className="contentTR">
          <div className="imageContainerTR">
            <img 
              src={`https://free.jeevansanskriti.com/images/${poojaImage}`}
              alt="Vastu Shanti Puja"
              className="pujaImageTR"
            />
          </div>
          <div className="detailsTR">
            <h1 className="titleTR">{poojaName}</h1>
            <p className="mb4TR">
            {poojaMessage}
              {/* Vastu Shanti is the process of worshipping God of Vastu (Vastu Purush), all the directions, Natural pressures, and five elements of nature (fire, water, earth, air, and space). People nowadays prefer to build houses according to Vastu shastra because Vastu is important to settle the planets according to the house buildup and its environment. */}
            </p>
            {/* <p className="mb4TR">
              Vastu Shanti Puja is beneficial to remove Vastu Dosha that occurred during the construction of the home. So Pandit Ji is important here who conducts the Vastu Puja in the right way using all the necessary samagri: Vastu Purusha Photo, Lord Ganpati Murti, Vastu Yantra, Kuldevta Photo, Betel Leaves, Panchagavya and a lot more for making it positive.
            </p> */}
            {/* <h2 className="subtitleTR">When shall one perform Vastu Shanti Puja?</h2>
            <p className="mb2TR">There are a lot of problems for which one can perform Vastu Shanti Puja. Those circumstances are,</p>
            <ul className="listTR">
              <li>If some Vastu Dosh occurs during House construction.</li>
              <li>Perform Vastu Shanti Puja precisely before Greha Pravesh Puja.</li>
              <li>Reconstruction of a home changes its Vastu pattern.</li>
              <li>Renovation of house or work area.</li>
              <li>If there is a shortage of area and getting difficult to adapt with Vedic measures.</li>
              <li>If people switch to the old house shall also perform Vastu Shanti Puja.</li>
              <li>If people live in the home for more than ten years, they shall accomplish Vastu Shanti Puja.</li>
              <li>If there are any mistakes while resolving interior house items, they also perform Vastu Shanti Puja.</li>
            </ul> */}
          </div>
        </div>
        <div className="bookingTR">
          <h2 className="subtitleTR">Book this Pooja</h2>
          <h3 className="subsubtitleTR">{poojaName}</h3>
          {features.map((fea, index) => (
            <div className="featureTR" key={index}>
              <span className="checkmarkTR">✔️</span> {fea}
            </div>
          ))}
          {/* <p className="refundPolicyTR">({poojaMessage})</p> */}
          <div className="mb4TR">
            <label className="blockLabelTR">Select Package</label>
            <div className="buttonGroupTR">
              <button
                className={`buttonTR ${selectedPackage === 'Starter' ? 'selected' : ''}`}
                onClick={() => handlePackageClick('Starter')}
              >
                Starter
              </button>
              <button   
                className={`buttonTR ${selectedPackage === 'Standard' ? 'selected' : ''}`}
                onClick={() => handlePackageClick('Standard')}
              >
                Standard
              </button>
              <button
                className={`buttonTR ${selectedPackage === 'Premium' ? 'selected' : ''}`}
                onClick={() => handlePackageClick('Premium')}
              >
                Premium
              </button>
            </div>
          </div>
          {selectedPackage && (
            <div className="packageDetailsTR">
              <h3 className="subsubtitleTR">{selectedPackage}</h3>
              {packagePrices[selectedPackage] === "Price not available" ? <p className="priceDetailsTR">{packagePrices[selectedPackage]}</p> : <p className="priceDetailsTR">Rs.{packagePrices[selectedPackage]}</p>}
              <ul className="listTR">
               {selectedPackage === "Starter" ? (startFeatures.map((item, index) => (
                  <li key={index}>{item}</li>
                ))):
                  selectedPackage === "Standard" ? (standardFeatures.map((item, index) => (
                    <li key={index}>{item}</li>
                  ))) : (premiumFeatures.map((item,index)=>(
                    <li key={index}>{item}</li>
                  )))
                } 
              </ul>
            </div>
          )}
          <div className="mb4TR">
            <label className="blockLabelTR">Select City</label>
            <div className="buttonGroupTR">
              <button
                className={`buttonTR ${selectedCity === 'Pune' ? 'selected' : ''}`}
                onClick={() => handleCityClick('Pune')}
              >
                Pune
              </button>
              <button
                className={`buttonTR ${selectedCity === 'Delhi NCR' ? 'selected' : ''}`}
                onClick={() => handleCityClick('Delhi NCR')}
              >
                Delhi NCR
              </button>
              <button
                className={`buttonTR ${selectedCity === 'Bangalore' ? 'selected' : ''}`}
                onClick={() => handleCityClick('Bangalore')}
              >
                Bangalore
              </button>
            </div>
          </div>
          <button className="bookButtonTR" onClick={handleBookNow}>Book Now</button>
        </div>
      </div>
      <Footer />
    </>
  );
}
