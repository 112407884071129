import React, { useEffect, useState } from "react";
import SideNav from "./SideNav";
import axios from "axios";
import "../Css/AdminAllProduct.css"; // Assuming you have CSS for styling

export default function AdminAllProduct() {
  const [products, setProducts] = useState([]);
  const [expandedDescriptions, setExpandedDescriptions] = useState({});
  const [editProduct, setEditProduct] = useState(null);
  const [filter, setFilter] = useState({ sort: "", category: "", search: "" });

  const fetchAllProducts = async () => {
    try {
      const { data } = await axios.get(
        "https://free.jeevansanskriti.com/api/v1/product"
      );
      const arrayOfProduct = data.data;
      setProducts(
        arrayOfProduct.map((pro) => ({
          id: pro.id,
          product_name: pro.product_name,
          main_image: pro.main_image,
          product_description: pro.product_description,
          product_short_description: pro.product_short_description,
          subcategory_id: pro.subcategory_id,
          price: pro.price,
          stock_quantity: pro.stock_quantity,
        }))
      );
    } catch (error) {
      console.log(error);
    }
  };
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setEditProduct({ ...editProduct, main_image: file });
  };
  useEffect(() => {
    fetchAllProducts();
  }, []);

  const toggleDescription = (id) => {
    setExpandedDescriptions((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  };

  const handleDelete = async (id) => {
    try {
      await axios.delete(
        `https://free.jeevansanskriti.com/api/v1/product/${id}`
      );
      setProducts(products.filter((product) => product.id !== id));
    } catch (error) {
      console.log(error);
    }
  };

  const handleEdit = (product) => {
    setEditProduct(product);
  };

  const handleUpdate = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("product_name", editProduct.product_name);
    formData.append("price", editProduct.price);
    formData.append("stock_quantity", editProduct.stock_quantity);
    formData.append("product_description", editProduct.product_description);

    if (editProduct.main_image instanceof File) {
      formData.append("main_image", editProduct.main_image); // New image upload
    } else {
      formData.append("main_image", editProduct.main_image); // Keep the existing image
    }

    try {
      await axios.put(
        `https://free.jeevansanskriti.com/api/v1/product/${editProduct.id}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      setProducts(
        products.map((product) =>
          product.id === editProduct.id
            ? { ...product, ...editProduct }
            : product
        )
      );
      setEditProduct(null);
    } catch (error) {
      console.log(error);
    }
  };

  const handleFilterChange = (e) => {
    setFilter({ ...filter, [e.target.name]: e.target.value });
  };

  const getFilteredProducts = () => {
    let filteredProducts = [...products];

    // Filter by search
    if (filter.search) {
      filteredProducts = filteredProducts.filter((product) =>
        product.product_name.toLowerCase().includes(filter.search.toLowerCase())
      );
    }

    // Filter by category
    if (filter.category) {
      filteredProducts = filteredProducts.filter(
        (product) => product.subcategory_id === filter.category
      );
    }

    // Sort products
    if (filter.sort === "alphabetical") {
      filteredProducts.sort((a, b) =>
        a.product_name.localeCompare(b.product_name)
      );
    } else if (filter.sort === "newest") {
      filteredProducts.sort(
        (a, b) => new Date(b.created_at) - new Date(a.created_at)
      );
    }

    return filteredProducts;
  };

  const filteredProducts = getFilteredProducts();

  return (
    <>
      <div className="admin-panel-container">
        <SideNav />
        <div className="content-container">
          <h1 className="page-title">All Jeevansanskriti's Product</h1>

          {/* Filter Options */}
          <div className="filter-container">
            <input
              type="text"
              name="search"
              placeholder="Search by name"
              value={filter.search}
              onChange={handleFilterChange}
            />
            <select
              name="category"
              value={filter.category}
              onChange={handleFilterChange}
            >
              <option value="">All Categories</option>
              <option value="Cement">Cement</option>
              <option value="2">Category 2</option>
              {/* Add more categories as needed */}
            </select>
            <select
              name="sort"
              value={filter.sort}
              onChange={handleFilterChange}
            >
              <option value="">Sort By</option>
              <option value="alphabetical">Alphabetical</option>
              <option value="newest">Newest</option>
            </select>
          </div>

          <div className="table-container">
            <table className="user-table">
              <thead>
                <tr>
                  <th>SR. No</th>
                  <th>Product Name</th>
                  <th>Product ID</th>
                  <th>Price</th>
                  <th>Stock</th>
                  <th>Product Description</th>
                  <th>Product Image</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {filteredProducts.map((product, index) => (
                  <tr key={product.id}>
                    <td>{index + 1}</td>
                    <td>{product.product_name}</td>
                    <td>{product.id}</td>
                    <td>{product.price}</td>
                    {/* <td>{product.discount}</td> */}
                    <td>{product.stock_quantity}</td>

                    <td
                      onClick={() => toggleDescription(product.id)}
                      style={{ cursor: "pointer" }}
                    >
                      {expandedDescriptions[product.id]
                        ? product.product_description
                        : `${product.product_description.substring(0, 30)}...`}
                    </td>
                    <td>
                      <img src={`https://free.shapier.in/images/${product.main_image}`} alt={product.product_name} width="50" height="50" />
                    </td>
                    <td>
                      <button
                        className="e-bt"
                        onClick={() => handleEdit(product)}
                      >
                        Edit
                      </button>
                      <button
                        className="d-bt"
                        onClick={() => handleDelete(product.id)}
                      >
                        Delete
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      {editProduct && (
        <div className="edit-modal">
          <div className="edit-modal-content">
            <h2>Edit Product</h2>
            <form onSubmit={handleUpdate}>
              <label>
                Product Name:
                <input
                  type="text"
                  value={editProduct.product_name}
                  onChange={(e) =>
                    setEditProduct({
                      ...editProduct,
                      product_name: e.target.value,
                    })
                  }
                />
              </label>
              <label>
                Price:
                <input
                  type="number"
                  value={editProduct.price}
                  onChange={(e) =>
                    setEditProduct({ ...editProduct, price: e.target.value })
                  }
                />
              </label>
              <label>
                Stock:
                <input
                  type="number"
                  value={editProduct.stock_quantity}
                  onChange={(e) =>
                    setEditProduct({
                      ...editProduct,
                      stock_quantity: e.target.value,
                    })
                  }
                />
              </label>
              <label>
                Product Description:
                <textarea
                  value={editProduct.product_description}
                  onChange={(e) =>
                    setEditProduct({
                      ...editProduct,
                      product_description: e.target.value,
                    })
                  }
                />
              </label>
              <label>
                Current Product Image:
                <div>
                  <img
                    src={`https://free.shapier.in/images/${editProduct.main_image}`}
                    alt={editProduct.product_name}
                    width="100"
                  />
                  <p>{editProduct.main_image}</p> {/* Display the image name */}
                </div>
              </label>
              <label>
                Upload New Image:
                <input
                  type="file"
                  accept="image/*"
                  onChange={handleImageChange}
                />
              </label>
              <div className="edit-modal-buttons">
                <button type="submit">Update</button>
                <button type="button" onClick={() => setEditProduct(null)}>
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </>
  );
}
