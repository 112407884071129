import React, { useState, useEffect, useRef } from 'react';
import '../Css/HomePage.css';
import Header from './Header';
import Carousel from './Carousel';
import Footer from './Footer';
import PopularPuja from './PopularPuja';
import BlogSection from './BlogSection';
import BookingSection from './BookingSection';
import axios from 'axios';
import ProductSection from './ProductSection';
import Banner from './Banner';
import Featuring from './Featuring';
import WhyChooseUs from './WhyChooseUs';
import Art, { Art2 } from './Art';
import useScrollAnimation from './useScrollAnimation';
import AboutSection from './AboutSection';
import PopupForm from './PopupForm';
import { useNavigate } from 'react-router-dom';

const HomePage = () => {
  const [bannerImage, setBannerImage] = useState([]);
  const [sideIamge_1, setSideImage_1] = useState([]);
  const [sideIamge_2, setSideImage_2] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isPopupOpen, setIsPopupOpen] = useState(true);
  const [showMessages, setShowMessages] = useState(false);
  const [selectedMessage, setSelectedMessage] = useState('');
  const messageOptionsRef = useRef(null);

  const navigate = useNavigate();

  const fetchHomePageBanner = async () => {
    try {
      const { data } = await axios.get('https://free.jeevansanskriti.com/api/v1/banner/banner_category/Home-main-banner');
      const response_for_side_one = await axios.get('https://free.jeevansanskriti.com/api/v1/banner/banner_category/side_banner_one');
      const response_for_side_two = await axios.get('https://free.jeevansanskriti.com/api/v1/banner/banner_category/side_banner_two');
      setBannerImage(data.data);
      setSideImage_1(response_for_side_one.data.data[0]?.banner_image);
      setSideImage_2(response_for_side_two.data.data[0]?.banner_image);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching banner data', error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchHomePageBanner();
  }, []);

  const closePopup = () => {
    setIsPopupOpen(false);
  };

  const handleSecondOfferClick = () => {
    // Navigate to the Store page with the discount filter and Pooja Kit category
    navigate('/store', { state: { selectedCategory: 'Pooja Kit', showDiscounted: true } });
  };

  const headerRef = useScrollAnimation();
  const carouselRef = useScrollAnimation();
  const offersRef = useScrollAnimation();
  const featuringRef = useScrollAnimation();
  const popularPujaRef = useScrollAnimation();
  const blogSectionRef = useScrollAnimation();
  const productSectionRef = useScrollAnimation();
  const whyChooseUsRef = useScrollAnimation();
  const aboutSectionRef = useScrollAnimation();

  const messages = [
    "Mahurat Consultant.",
    "Baby Problem",
    "Marriage Problem",
    "Married Life Problem",
    "Problem related to Business",
    "Vastu Problem",
  ];

  const handleNewButtonClick = () => {
    setShowMessages(!showMessages);
  };

  const handleMessageClick = (message) => {
    setSelectedMessage(message);
    setShowMessages(false);

    // Redirect to WhatsApp with the selected message
    window.open(`https://wa.me/9540004671?text=${encodeURIComponent(message)}`, '_blank');
  };

  const handleClickOutside = (event) => {
    if (messageOptionsRef.current && !messageOptionsRef.current.contains(event.target)) {
      setShowMessages(false);
    }
  };

  useEffect(() => {
    // Add event listener to detect clicks outside of the message options
    document.addEventListener('mousedown', handleClickOutside);
    
    return () => {
      // Remove event listener on cleanup
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  if (loading) {
    return (
      <div className="loader-container">
        <div className="loader">
          <div className="dot1"></div>
          <div className="dot2"></div>
        </div>
      </div>
    );
  }

  return (
    <>
      {isPopupOpen && <PopupForm onClose={closePopup} />}
      <Header ref={headerRef.ref} />
      <div className="container">
        <div className="blank-container"></div>
        <div className="main-content">
          <div ref={carouselRef.ref} className="main-banner">
            <Carousel images={bannerImage} interval={3000} />
          </div>
          <div ref={offersRef.ref} className="offers">
            <div className="offer">
              <img
                src={`https://free.jeevansanskriti.com/images/${sideIamge_1}`}
                alt="Special Offer"
                className="offer-image"
              />
              <div className="offer-text">
                <h3>SAVE 20%</h3>
                <h2 className="banner-main-text">Special Offer</h2>
                <button className="shop-now">Shop Now</button>
              </div>
            </div>
            <div className="offer" onClick={handleSecondOfferClick}>
              <img
                src={`https://free.jeevansanskriti.com/images/${sideIamge_2}`}
                alt="Special Offer"
                className="offer-image"
              />
              <div className="offer-text">
                <h3>Product at Discount</h3>
                {/* <h2 className="banner-main-text">Special Offer</h2> */}
                <button className="shop-now">Shop Now</button>
              </div>
            </div>
          </div>
        </div>

        <div ref={featuringRef.ref}>
          <Featuring />
        </div>
        <div ref={popularPujaRef.ref}>
          <PopularPuja />
        </div>

        <div className="ticket-container">
          <img src="../1.jpg" alt="Ticket Image" className="ticket-image" />
        </div>

        <div ref={blogSectionRef.ref}>
          <BlogSection />
        </div>
        <div ref={aboutSectionRef.ref}>
          <AboutSection />
        </div>
        
        <div ref={productSectionRef.ref}>
          <ProductSection />
        </div>
        <div ref={whyChooseUsRef.ref}>
          <WhyChooseUs />
        </div>
      </div>
      <Footer />

      <a
        href="https://wa.me/9540004671"
        className="whatsapp-button"
        target="_blank" 
        rel="noopener noreferrer"
      >
        <img
          src="https://upload.wikimedia.org/wikipedia/commons/6/6b/WhatsApp.svg"
          alt="WhatsApp"
        />
      </a>

      <div className="message-button-container">
        <button className="message-button" onClick={handleNewButtonClick}>
          <img
            src="../astrology.png"
            alt="Horoscope"
            className="horoscope-icon"
          />
        </button>
        {showMessages && (
          <div className="message-options" ref={messageOptionsRef}>
            {messages.map((message, index) => (
              <div
                key={index}
                className="message-option"
                onClick={() => handleMessageClick(message)}
              >
                {message}
              </div>
            ))}
          </div>
        )}
      </div>
    </>
  );
};

export default HomePage;
