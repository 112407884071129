import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import Header from "./Header";
import Footer from "./Footer";
import "../Css/CartPage.css";
import axios from "axios";

export default function CartPage() {
  const [cartItems, setCartItems] = useState([]);
  const storedData = JSON.parse(localStorage.getItem("data"));
  const token = storedData ? storedData.token : null;
  const userId = storedData ? storedData.userId : null;
  const navigate = useNavigate();

  const fetchCartItem = async () => {
    if (!userId || !token) {
      console.log("User ID or token is not available");
      return;
    }
    try {
      const { data } = await axios.get(
        `https://free.jeevansanskriti.com/api/v1/cart_by_user_id/${userId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log("Fetched Cart Items:", data); // Detailed log
      if (data && data.data) {
        setCartItems(data.data);
      } else {
        console.log("No data received or incorrect data structure");
      }
    } catch (error) {
      console.log("Error fetching cart items:", error);
    }
  };

  useEffect(() => {
    console.log("User ID:", userId);
    fetchCartItem();
  }, []);

  const handleQuantityChange = (id, quantity) => {
    setCartItems(
      cartItems.map((item) => (item.id === id ? { ...item, quantity } : item))
    );
  };

  const handleDelete = (id) => {
    setCartItems(cartItems.filter((item) => item.id !== id));
  };

  const total = cartItems.reduce(
    (acc, item) => acc + Number(item.price) * item.quantity,
    0
  );

  const proceedToCheckout = () => {
    navigate('/puja-checkout-for-cart', { state: { cartItems, total } });
  };

  return (
    <>
      <Header />
      <h2 className="cart-heading">Cart Summary</h2>
      <div className="cart-container">
        <div className="cart-items">
          <div className="cart-header">
            <span className="cart-header-product">Product</span>
            <span className="cart-header-price">Price</span>
            <span className="cart-header-quantity">Quantity</span>
            <span className="cart-header-subtotal">Subtotal</span>
          </div>
          {cartItems.length > 0 ? (
            cartItems.map((item) => (
              <div key={item.id} className="cart-item">
                <button
                  className="delete-button"
                  onClick={() => handleDelete(item.id)}
                >
                  ✖
                </button>
                {console.log(item)}
                <img src={`https://free.jeevansanskriti.com/images/${item.image}`} alt="" className="item-image" />
                <span className="item-name">{item.product_name}</span>
                <span className="item-price">₹{Number(item.price).toFixed(2)}</span>
                <div className="quantity-controls">
                  <button onClick={() => handleQuantityChange(item.id, Math.max(item.quantity - 1, 1))}>-</button>
                  <input
                    type="number"
                    min="1"
                    value={item.quantity}
                    onChange={(e) =>
                      handleQuantityChange(item.id, parseInt(e.target.value))
                    }
                    className="item-quantity"
                  />
                  <button onClick={() => handleQuantityChange(item.id, item.quantity + 1)}>+</button>
                </div>
                <span className="item-total">₹{(Number(item.price) * item.quantity).toFixed(2)}</span>
              </div>
            ))
          ) : (
            <p>No items in the cart</p>
          )}
          
        </div>
        <div className="order-summary">
          <h2>Basket totals</h2>
          <div className="summary-detail">
            <span>Subtotal</span>
            <span>₹{total.toFixed(2)}</span>
          </div>
          <div className="summary-detail total-detail">
            <span>Total</span>
            <span>₹{total.toFixed(2)}</span>
          </div>
          <button className="proceed-checkout" onClick={proceedToCheckout}>Proceed to checkout</button>
        </div>
      </div>
      <Footer />
    </>
  );
}
