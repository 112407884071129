import React, { useEffect, useState } from 'react';
import axios from 'axios';
import SideNav from './SideNav'; // Adjust the import path based on your project structure
import '../Css/AdminServices.css'; // Ensure you create this CSS file to style the table

export default function AdminServices() {
    const [services, setServices] = useState([]);
    const [filter, setFilter] = useState({ name: '', email: '' });

    const fetchAllServices = async () => {
        try {
            const { data } = await axios.get('https://free.jeevansanskriti.com/api/v1/service', {
                params: {
                    name: filter.name || undefined,
                    email: filter.email || undefined
                }
            });
            const arrayOfServices = data.data;
            setServices(arrayOfServices.map((service) => ({
                id: service.id,
                name: service.name,
                email: service.email,
                mobile: service.mobile,
                event_date: service.event_date,
                guests: service.guests,
                additional_details: service.additional_details,
                created_at: service.created_at,
            })));
        } catch (error) {
            console.error('Error fetching services:', error);
        }
    };

    useEffect(() => {
        fetchAllServices();
    }, [filter]);

    const handleFilterChange = (e) => {
        setFilter({ ...filter, [e.target.name]: e.target.value });
    };

    const formatDate = (dateString) => {
        if (!dateString) return 'N/A';
        const options = { year: 'numeric', month: 'short', day: 'numeric' };
        return new Date(dateString).toLocaleDateString(undefined, options);
    };

    return (
        <>
            <div className="admin-panel-container">
                <SideNav />
                <div className="content-container">
                    <h1 className="page-title">Service Requests</h1>

                    <div className="filter-container">
                        <input
                            type="text"
                            name="name"
                            value={filter.name}
                            onChange={handleFilterChange}
                            placeholder="Search by Name"
                        />
                        <input
                            type="text"
                            name="email"
                            value={filter.email}
                            onChange={handleFilterChange}
                            placeholder="Search by Email"
                        />
                        <button onClick={fetchAllServices}>Apply Filters</button>
                    </div>

                    <div className="table-container">
                        <table className="service-table">
                            <thead>
                                <tr>
                                    <th>SR. No</th>
                                    <th>Name</th>
                                    <th>Email</th>
                                    <th>Phone</th>
                                    <th>Event Date</th>
                                    <th>Guests</th>
                                    <th>Additional Details</th>
                                    <th>Created At</th>
                                </tr>
                            </thead>
                            <tbody>
                                {services.map((service, index) => (
                                    <tr key={service.id}>
                                        <td>{index + 1}</td>
                                        <td>{service.name}</td>
                                        <td>{service.email}</td>
                                        <td>{service.mobile}</td>
                                        <td>{formatDate(service.event_date)}</td>
                                        <td>{service.guests}</td>
                                        <td>{service.additional_details}</td>
                                        <td>{formatDate(service.created_at)}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>
    );
}
