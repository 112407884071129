import { useInView } from 'react-intersection-observer';
import { useEffect, useRef } from 'react';

const useScrollAnimation = (threshold = 0.1, triggerOnce = true) => {
  const [ref, inView] = useInView({
    triggerOnce,
    threshold,
  });

  const elementRef = useRef();

  useEffect(() => {
    if (inView && elementRef.current) {
      elementRef.current.classList.add('animate');
    }
  }, [inView]);

  return { ref: (node) => { elementRef.current = node; ref(node); } };
};

export default useScrollAnimation;
