import React, { useEffect, useState } from "react";
import "../Css/BlogDetailsPage.css";
import Footer from "./Footer";
import Header from "./Header";
import BlogSection from "./BlogSection";
import axios from "axios";
import { useLocation, useParams } from "react-router-dom";

export default function BlogDetailPage() {
  const [blog, setBlog] = useState([]);
  const { id } = useParams();
  const location = useLocation();
  const fetchBlog = async () => {
    try {
      const { data } = await axios.get(
        `https://free.jeevansanskriti.com/api/v1/blogs/${id}`
      );
      const arrayOfBlog = data.data;
      setBlog(arrayOfBlog);
    } catch (error) {
      console.error("Error fetching the blog data:", error);
    }
  };
  useEffect(()=>{
    window.scrollTo(0,0);

  },location.pathname)
  useEffect(() => {
    fetchBlog();
  }, []);

  return (
    <>
      <Header />
      {blog.map((bo) => (
        <div className="Blog-Details-container" key={bo.id}>
          <div className="Blog-Details-image-container">
            <img
              src={`https://free.jeevansanskriti.com/images/${bo.blog_image}`}
              alt={bo.title}
              className="Blog-Details-image"
            />
          </div>
          <div className="Blog-Details-content">
            <h1 className="Blog-Details-title">{bo.title}</h1>
            <div className="Blog-Details-meta">
              <span className="Blog-Details-date">{bo.created_at}</span> |{" "}
              <span className="Blog-Details-author">{bo.author}</span>
            </div>
            <p className="Blog-Details-description">{bo.short_description}</p>
            <div className="Blog-Details-text">
              {bo.description}
            </div>
          </div>
        </div>
      ))}
      <BlogSection />
      <Footer />
    </>
  );
}
