import React, { useState } from 'react';
import SideNav from './SideNav';

export default function AdminBanners() {
  const [file, setFile] = useState(null);
  const [category, setCategory] = useState('');
  const [message, setMessage] = useState('');

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleCategoryChange = (e) => {
    setCategory(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('banner_image', file);
    formData.append('banner_category', category);
    try {
      const response = await fetch('https://free.jeevansanskriti.com/api/v1/banner', {
        method: 'POST',
        body: formData,
      }
    );
      const result = await response.json();
      setMessage(result.message);
    } catch (error) {
      console.error('Error:', error)
      setMessage('Something went wrong');
    }
  };

  return (
    <div className="admin-panel-container">
      <SideNav />
      <div className="form-container">
        <form onSubmit={handleSubmit}>
          <label>
            Banner Image:
            <input type="file"onChange={handleFileChange} required />
          </label>
          <label>
            Category:
            <input type="text" value={category} onChange={handleCategoryChange} required />
          </label>
          <button type="submit">Upload Banner</button>
        </form>
        {message && <p>{message}</p>}
      </div>
    </div>
  );
}
