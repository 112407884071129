import React, { useEffect } from "react";
import "../Css/PoojaCard.css";
import { useLocation, useNavigate } from "react-router-dom";

export default function PoojaCard({ details }) {
  const location = useLocation();
  const navigate = useNavigate();

  const toPoojaBookingPage = (id) => {
    window.open(`/booking/${id}`);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  if (!Array.isArray(details)) {
    return null; // Or render a message indicating no details available
  }

  return (
    <div className="popular-puja-container">
      <h2 className="popular-puja-title">Popular Puja</h2>
      <div className="popular-puja-grid">
        {details.map((pooj) => (
          <div key={pooj.id} className="puja-card" onClick={() => toPoojaBookingPage(pooj.id)}>
            <img
              src={`https://free.jeevansanskriti.com/images/${pooj.image}`}
              alt={pooj.pooja_name}
            />
            <div className="puja-card-content">
              <h3 className="puja-card-title">{pooj.pooja_name}</h3>
              {pooj.starting_price === "Price not available" ? (
                <p>Click me and Worship begins here</p>
              ) : (
                <p className="puja-card-message">
                  Starting from {pooj.starting_price}
                </p>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}