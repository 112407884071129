import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import HomePage from "./Component/HomePage";
import BlogDetailPage from "./Component/BlogDetailPage";
import BookingPage from "./Component/BookingPage";
import CheckoutPage from "./Component/CheckoutPage";
import CartPage from "./Component/CartPage";
import Service from "./Component/Service";
import Contactus from "./Component/Contactus";
import AdminPanel from "./Component/AdminPanel";
import ProductDetail from "./Component/ProductDetail";
import Store from "./Component/Store";
import AdminBanners from "./Component/AdminBanners";
import AdminPooja from "./Component/AdminPooja";
import AdminPoojaPriceTier from "./Component/AdminPoojaPriceTier";
import AdminProductImages from "./Component/AdminProductImages";
import AdminFeatures from "./Component/AdminFeatures";
import LoginPage from "./Component/LoginPage";
import SignUpPage from "./Component/SignUpPage";
import CheckoutForm from "./Component/CheckoutForm";
import OrderConfirmation from "./Component/OrderConfirmation";
import PujaCheckoutPage from "./Component/PujaCheckoutPage";
import AdminBlog from "./Component/AdminBlog";
import AdminOrders from "./Component/AdminOrders";
import AdminServices from "./Component/AdminServices";
import AdminContactUs from "./Component/AdminContactUs";
import AdminUsers from "./Component/AdminUsers";
import PujaCheckoutPageForCart from "./Component/PujaCheckoutPageForCart";

import AdminLoginPage from "./Component/AdminLoginPage";
import AdminProducts from "./Component/AdminAllProduct";
import AdminAllProduct from "./Component/AdminAllProduct";

import FinalCheckForPuja from "./Component/FinalCheckForPuja";
import AdminAllPoojas from "./Component/AdminAllPoojas";


function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/blog/:id" element={<BlogDetailPage />} />
        <Route path="/booking/:id" element={<BookingPage />} />
        <Route path="/checkout" element={<CheckoutForm />} />
        <Route path="/cart" element={<CartPage />} />
        <Route path="/services" element={<Service />} />
        <Route path="/contact" element={<Contactus />} />
        <Route path="/store" element={<Store />} />
        <Route path="/admin" element={<AdminLoginPage />} />
        <Route path="/admin-panel" element={<AdminPanel />} />
        <Route path="/admin-product-images" element={<AdminProductImages />} />
        <Route path="/admin-pooja-features" element={<AdminFeatures/>}/>
        <Route path="/admin/Banners" element={<AdminBanners />} />
        <Route path="/admin-pooja" element={<AdminPooja />} />
        <Route path="/admin-pooja-price" element={<AdminPoojaPriceTier />} />
        <Route path="/details/:id" element={<ProductDetail />} />
        <Route path="/details" element={<ProductDetail />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/sign-up" element={<SignUpPage />} />        
        <Route path="/order-confirmation" element={<OrderConfirmation />} />        
        <Route path="/puja-checkout" element={<PujaCheckoutPage />} /> 
        <Route path="/puja-checkout-for-cart" element={<PujaCheckoutPageForCart/>} />       
        <Route path="/admin-blog" element={<AdminBlog />} />        
        <Route path="/admin/orders" element={<AdminOrders />} />
        <Route path="/admin/service-enquiry" element={<AdminServices/>} />
        <Route path="/admin/contact" element={<AdminContactUs/>} />
        <Route path="/admin/Users" element={<AdminUsers/>} />
        <Route path="/admin/all_product" element={<AdminAllProduct/>} />
        <Route path="/admin/all_puja" element={<AdminAllPoojas/>} />
        <Route path="/puja-final_checkout" element={<FinalCheckForPuja/>}/>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
