import React, { useState, useEffect } from 'react';
import '../Css/Carousel.css';

const Carousel = ({ images, interval, height }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentIndex(prevIndex => (prevIndex + 1) % images.length);
    }, interval);

    return () => clearInterval(timer);
  }, [images.length, interval]);

  const prevSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
  };

  const nextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const goToSlide = (index) => {
    setCurrentIndex(index);
  };

  return (
    <div className="carousel" style={{ height: `${height}px` }}>
      <button className="carousel-button prev" onClick={prevSlide}>‹</button>
      <div className="carousel-inner" style={{ transform: `translateX(-${currentIndex * 100}%)` }}>
        {images.map((image, index) => (
          <div
            key={index}
            className={`carousel-item ${index === currentIndex ? 'active' : ''}`}
          >
            <img src={`https://free.jeevansanskriti.com/images/${image.banner_image}`} alt={"jeevansanskriti.com"} className="carousel-image" style={{ height: `${height}px` }} />
            {/* <div className="carousel-text">
              <h2 className='banner-main-text'>{image.title}</h2>
              <p>{image.description}</p>
          
            </div> */}
          </div>
        ))}
      </div>
      <button className="carousel-button next" onClick={nextSlide}>›</button>
      <div className="carousel-dots">
        {images.map((_, index) => (
          <span
            key={index}
            className={`carousel-dot ${index === currentIndex ? 'active' : ''}`}
            onClick={() => goToSlide(index)}
          ></span>
        ))}
      </div>
    </div>
  );
};

export default Carousel;
