import React, { useState } from "react";
import axios from "axios"; // Make sure to import axios
import "../Css/Contactus.css";
import Header from "./Header";
import Footer from "./Footer";

export default function Contactus() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    mobile: "",
    message: "",
  });

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [id]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const params = {
      name: formData.name,
      email: formData.email,
      mobile: formData.mobile,
      message: formData.message,
    };

    try {
      await axios.post('https://free.jeevansanskriti.com/api/v1/contact_us', params);
      alert('Contact request submitted successfully');
    } catch (error) {
      console.error('Error submitting the service request:', error);
      alert('Failed to submit Contact request');
    }
  };

  return (
    <>
      <Header />
      <div className="ContactPage-container">
        <div className="ContactPage-about-us">
          <h1>About Us</h1>
          <p>
            Welcome to our religious puja booking website. We are dedicated to
            providing a seamless and spiritual experience for booking various
            pujas and religious ceremonies. Our mission is to bring the divine
            closer to you by offering easy access to a wide range of puja
            services.
          </p>
        </div>
        <div className="ContactPage-contact-form">
          <h1>Contact Us</h1>
          <form
            className="ContactPage-contact-form-details"
            onSubmit={handleSubmit}
          >
            <div className="ContactPage-form-group">
              <label htmlFor="name">Name</label>
              <input
                type="text"
                id="name"
                value={formData.name}
                onChange={handleChange}
                placeholder="Enter your name"
                required
              />
            </div>
            <div className="ContactPage-form-group">
              <label htmlFor="email">Email</label>
              <input
                type="email"
                id="email"
                value={formData.email}
                onChange={handleChange}
                placeholder="Enter your email"
                required
              />
            </div>
            <div className="ContactPage-form-group">
              <label htmlFor="mobile">Phone Number</label> {/* Changed "phone" to "mobile" */}
              <input
                type="tel"
                id="mobile"
                value={formData.mobile}
                onChange={handleChange}
                placeholder="Enter your phone number"
                required
              />
            </div>
            <div className="ContactPage-form-group">
              <label htmlFor="message">Message</label>
              <textarea
                id="message"
                value={formData.message}
                onChange={handleChange}
                rows="4"
                placeholder="Enter your message"
              ></textarea>
            </div>
            <button type="submit" className="ContactPage-submit-button">
              Submit
            </button>
          </form>
        </div>
      </div>
      <Footer />
    </>
  );
}
