import React from 'react';
import '../Css/Footer.css';
import { FaMapMarkerAlt, FaPhone, FaEnvelope, FaFacebook, FaTwitter, FaInstagram } from 'react-icons/fa';

export default function Footer() {
  return (
    <footer className="footer">
      <div className="footer-container">
        <div className="footer-section about">
          <h2>About Us</h2>
          <p>
            We are dedicated to making your puja experiences seamless and fulfilling. Our goal is to provide the best services for booking various pujas and rituals, ensuring peace and prosperity for all.
          </p>
        </div>
        <div className="footer-section links">
          <h2>Quick Links</h2>
          <ul>
            <li><a href="/privacy-policy">Privacy Policy</a></li>
            <li><a href="/terms-and-conditions">Terms and Conditions</a></li>
          </ul>
        </div>
        <div className="footer-section site-links">
          <h2>Site Links</h2>
          <ul>
            <li><a href="/">Home</a></li>
            <li><a href="/about">About</a></li>
            <li><a href="/services">Services</a></li>
            <li><a href="/contact">Contact</a></li>
          </ul>
        </div>
        <div className="footer-section contact">
          <h2>Contact Information</h2>
          <p>
            <FaMapMarkerAlt /> 1234 Street Name, City, State, Country
          </p>
          <p>
            <FaPhone /> +91 9540004671
          </p>
          <p>
            <FaEnvelope /> jeevansanskriti@gmail.com
          </p>
        </div>
      </div>
      <div className="footer-social">
        <a href="https://facebook.com" target="_blank" rel="noopener noreferrer"><FaFacebook /></a>
        <a href="https://twitter.com" target="_blank" rel="noopener noreferrer"><FaTwitter /></a>
        <a href="https://instagram.com/jeevan_sanskriti_official" target="" rel="noopener noreferrer"><FaInstagram /></a>
      </div>
      <div className="footer-bottom">
        <p>&copy; 2024 Your Company. All rights reserved.</p>
      </div>
    </footer>
  );
}
