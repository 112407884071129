  import React, { useEffect, useState } from "react";
  import "../Css/ProductSection.css";
  import axios from "axios";
  import { useNavigate } from "react-router-dom";

  const truncateText = (text, length) => {
    if (text.length <= length) return text;
    return text.substring(0, length) + "...";
  };

  export default function ProductSection() {
    const [scrollX, setScrollX] = useState(0);
    const [selectedSamagri, setSelectedSamagri] = useState([]);
    const [isAddedToCart, setIsAddedToCart] = useState(false);
    const storedData = JSON.parse(localStorage.getItem("data"));
    const userId = storedData ? storedData.userId : null;
    const token = storedData ? storedData.token : null;
    const navigate = useNavigate();
    const toDetailsPage = (id) => {
      window.open(`/details/${id}`);
    };
    const toProductDetailPage = async (product_id) => {
      if (userId) {
        try {
          await axios.post(
            `https://free.jeevansanskriti.com/api/v1/cart`,
            {
              user_id: userId,
              product_id: product_id,
              quantity: 1,
            },
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          setIsAddedToCart(true); // Disable the button after adding to cart
          alert("Product added to cart");
        } catch (error) {
          console.log(error);
        }
      } else {
        alert("Please login before adding product to cart");
      }
    };

    const fetchSamagri = async () => {
      try {
        const { data } = await axios.get(
          `https://free.jeevansanskriti.com/api/v1/product`
        );
      
        setSelectedSamagri(data.data);
      
      } catch (error) {
        console.error("Error fetching Samagri:", error);
      }
    };

    useEffect(() => {
      fetchSamagri();
    }, []);

    const scroll = (direction) => {
      const container = document.querySelector(".PUJA-product-section");
      const scrollAmount = direction === "left" ? -250 : 250;
      container.scrollBy({ left: scrollAmount, behavior: "smooth" });
      setScrollX(scrollX + scrollAmount);
    };

    return (
      <>
        <h2 className="blog-section-title">Latest Product</h2>
        <div className="PUJA-product-container">
          <button
            className="PUJA-scroll-button left"
            onClick={() => scroll("left")}
          >
            &lt;
          </button>
          <div className="PUJA-product-section">
            {selectedSamagri.map((product) => (
              <div className="PUJA-product-card" onClick={()=>toDetailsPage(product.id)} key={product.id}>
                <img
                  src={`https://free.jeevansanskriti.com/images/${product.main_image}`}
                  alt={product.product_name}
                  className="PUJA-product-image"
                />
                <h3 className="PUJA-product-name">{product.product_name}</h3>
                <p className="PUJA-product-description">
                  {truncateText(product.product_description, 50)}
                </p>
                <p className="PUJA-product-price">Rs.{product.price}</p>
                <button
                  className="PUJA-add-to-cart-button"
                  onClick={() => toProductDetailPage(product.id)}
                  disabled={isAddedToCart}
                >
                  {isAddedToCart ? `&#10003;` : "Add to Cart"}
                </button>
              </div>
            ))}
          </div>
          <button
            className="PUJA-scroll-button right"
            onClick={() => scroll("right")}
          >
            &gt;
          </button>
        </div>
      </>
    );
  }
