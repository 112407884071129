import React, { useEffect, useState } from 'react';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import '../Css/BlogSection.css';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { format } from 'date-fns';

export default function BlogSection() {
  const [blog, setBlog] = useState([]);
  const navigate = useNavigate();

  const fetchBlog = async () => {
    const { data } = await axios.get(`https://free.jeevansanskriti.com/api/v1/blogs`);
    const arrayOfBlog = data.data;
    setBlog(arrayOfBlog);
  };

  useEffect(() => {
    fetchBlog();
  }, []);

  const toBlogPage = (id) => {
    navigate(`/blog/${id}`);
  };

  const scrollRef = React.useRef(null);
  const scrollLeft = () => {
    scrollRef.current.scrollBy({
      left: -300,
      behavior: 'smooth'
    });
  };

  const scrollRight = () => {
    scrollRef.current.scrollBy({
      left: 300,
      behavior: 'smooth'
    });
  };

  return (
    <div className="blog-section">
      <h2 className="blog-section-title">Latest Blog Posts</h2>
      <div className="blog-posts-container">
        <button className="scroll-button left" onClick={scrollLeft}>
          <FaChevronLeft />
        </button>
        <div className="blog-posts" ref={scrollRef}>
          {blog.map((post) => (
            <div key={post.id} className="blog-post-card" onClick={() => toBlogPage(post.id)}>
              <img src={`https://free.jeevansanskriti.com/images/${post.blog_image}`} alt={post.title} className="blog-post-image" />
              <div className="blog-post-content">
                <h3 className="blog-post-title">{post.title}</h3>
                <p className="blog-post-date">{format(new Date(post.created_at), 'MMMM d, yyyy h:mm a')}</p>
                {/* <p className="blog-post-excerpt">{post.short_description}</p> */}
              </div>
            </div>
          ))}
        </div>
        <button className="scroll-button right" onClick={scrollRight}>
          <FaChevronRight />
        </button>
      </div>
    </div>
  );
}
