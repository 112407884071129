import React, { useEffect, useState } from 'react';
import axios from 'axios';
import SideNav from './SideNav'; // Adjust the import path based on your project structure
import '../Css/AdminUsers.css'; // Ensure you create this CSS file to style the table

export default function AdminUsers() {
    const [users, setUsers] = useState([]);
    const [filter, setFilter] = useState({ username: '', email: '' });

    const fetchAllUsers = async () => {
        try {
            const { data } = await axios.get('https://free.jeevansanskriti.com/api/v1/user', {
                params: {
                    username: filter.username || undefined,
                    email: filter.email || undefined
                }
            });
            const arrayOfUsers = data.data;
            setUsers(arrayOfUsers.map((user) => ({
                id: user.id,
                username: user.username,
                email: user.email,
                mobile: user.mobile,
                created_at: user.created_at,
                updated_at: user.updated_at
            })));
        } catch (error) {
            console.error('Error fetching users:', error);
        }
    };

    useEffect(() => {
        fetchAllUsers();
    }, [filter]);

    const handleFilterChange = (e) => {
        setFilter({ ...filter, [e.target.name]: e.target.value });
    };

    const formatDate = (dateString) => {
        if (!dateString) return 'N/A';
        const options = { year: 'numeric', month: 'short', day: 'numeric' };
        return new Date(dateString).toLocaleDateString(undefined, options);
    };

    return (
        <>
            <div className="admin-panel-container">
                <SideNav />
                <div className="content-container">
                    <h1 className="page-title">Users</h1>

                    <div className="filter-container">
                        <input
                            type="text"
                            name="username"
                            value={filter.username}
                            onChange={handleFilterChange}
                            placeholder="Search by Username"
                        />
                        <input
                            type="text"
                            name="email"
                            value={filter.email}
                            onChange={handleFilterChange}
                            placeholder="Search by Email"
                        />
                        <button onClick={fetchAllUsers}>Apply Filters</button>
                    </div>

                    <div className="table-container">
                        <table className="user-table">
                            <thead>
                                <tr>
                                    <th>SR. No</th>
                                    <th>Username</th>
                                    <th>Email</th>
                                    <th>Phone</th>
                                    <th>Created At</th>
                                    <th>Updated At</th>
                                </tr>
                            </thead>
                            <tbody>
                                {users.map((user, index) => (
                                    <tr key={user.id}>
                                        <td>{index + 1}</td>
                                        <td>{user.username}</td>
                                        <td>{user.email}</td>
                                        <td>{user.mobile}</td>
                                        <td>{formatDate(user.created_at)}</td>
                                        <td>{user.updated_at ? formatDate(user.updated_at) : 'N/A'}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>
    );
}
