import React, { useState } from 'react';
import SideNav from './SideNav';
import axios from 'axios';

export default function AdminProductImages() {
  const [productImage, setProductImage] = useState({
    product_id: '',
    image_url: null,
  });

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setProductImage({
      ...productImage,
      image_url: file,
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setProductImage({
      ...productImage,
      [name]: value,
    });
  };

  const handleImageSubmit = async (e) => {
    e.preventDefault();
    try {
      const formData = new FormData();
      formData.append('product_id', productImage.product_id);
      formData.append('image_url', productImage.image_url);

      const response = await axios.post(
        'https://free.jeevansanskriti.com/api/v1/product_image',
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );
    } catch (error) {
      if (error.response) {
        console.error('Error response:', error.response.data);
      } else if (error.request) {
        console.error('Error request:', error.request);
      } else {
        console.error('Error message:', error.message);
      }
      console.error('Error config:', error.config);
    }
  };

  return (
    <div className="admin-panel-container">
      <SideNav />
      <div className="form-container">
        <form onSubmit={handleImageSubmit}>
          <label>
            Product ID:
            <input
              type="text"
              name="product_id"
              value={productImage.product_id}
              onChange={handleChange}
              required
            />
          </label>
          <label>
            Image:
            <input
              type="file"
              name="image_url"
              onChange={handleFileChange}
              required
            />
          </label>
          <button type="submit">Upload Image</button>
        </form>
      </div>
    </div>
  );
}
