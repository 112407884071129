import React, { useEffect, useState } from 'react';
import axios from 'axios';
import '../Css/AdminAllPoojas.css'; // Ensure this CSS file exists for styling
import SideNav from './SideNav';

const AdminAllPoojas = () => {
  const [poojas, setPoojas] = useState([]);
  const [expandedDescriptions, setExpandedDescriptions] = useState({});
  const [editPooja, setEditPooja] = useState(null);
  const [filter, setFilter] = useState({ sort: '', category: '', search: '', poojaId: '' });

  useEffect(() => {
    const fetchAllPoojas = async () => {
      try {
        const { data } = await axios.get('https://free.jeevansanskriti.com/api/v1/pooja');
        setPoojas(data.data); // Adjust based on the response structure
      } catch (error) {
        console.error('Error fetching pooja data:', error);
      }
    };

    fetchAllPoojas();
  }, []);

  const toggleDescription = (id) => {
    setExpandedDescriptions((prevState) => ({
      ...prevState,
      [id]: !prevState[id]
    }));
  };

  const handleDelete = async (id) => {
    try {
      await axios.delete(`https://free.jeevansanskriti.com/api/v1/pooja/${id}`);
      setPoojas(poojas.filter((pooja) => pooja.id !== id));
    } catch (error) {
      console.error('Error deleting pooja:', error);
    }
  };

  const handleEdit = (pooja) => {
    const priceTiers = pooja.price_tiers.length > 0 ? pooja.price_tiers : [
      { id: 'starter', tier_name: 'Starter', price: '' },
      { id: 'standard', tier_name: 'Standard', price: '' },
      { id: 'premium', tier_name: 'Premium', price: '' }
    ];
    setEditPooja({ ...pooja, price_tiers: priceTiers });
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
  
    try {
      const updatePromises = editPooja.price_tiers.map((tier) => {
        return axios.put(`https://free.jeevansanskriti.com/api/v1/price_tier/${tier.id}/`, {
          pooja_id: editPooja.id,
          tier_name: tier.tier_name,
          price: tier.price,
          features: tier.features, // If features need to be updated
        });
      });
  
      await Promise.all(updatePromises);
  
      // Update the state with the new pooja details
      const updatedPoojas = poojas.map((pooja) =>
        pooja.id === editPooja.id ? editPooja : pooja
      );
  
      setPoojas(updatedPoojas);
      setEditPooja(null);
    } catch (error) {
      console.error('Error updating pooja:', error);
    }
  };
  

  const handleFilterChange = (e) => {
    setFilter({ ...filter, [e.target.name]: e.target.value });
  };

  const getFilteredPoojas = () => {
    let filteredPoojas = [...poojas];

    if (filter.search) {
      filteredPoojas = filteredPoojas.filter(pooja =>
        pooja.pooja_name.toLowerCase().includes(filter.search.toLowerCase())
      );
    }

    if (filter.poojaId) {
      filteredPoojas = filteredPoojas.filter(pooja =>
        pooja.id.toString() === filter.poojaId.toString()
      );
    }

    if (filter.category) {
      filteredPoojas = filteredPoojas.filter(pooja =>
        pooja.subcategory_id === filter.category
      );
    }

    if (filter.sort === 'alphabetical') {
      filteredPoojas.sort((a, b) => a.pooja_name.localeCompare(b.pooja_name));
    } else if (filter.sort === 'newest') {
      filteredPoojas.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
    }

    return filteredPoojas;
  };

  const filteredPoojas = getFilteredPoojas();

  return (
    <div className="admin-panel-container">
      <SideNav />
      <div className="content-container">
        <h1 className="page-title">All Poojas</h1>

        {/* Filter Options */}
        <div className="filter-container">
          <input
            type="text"
            name="search"
            placeholder="Search by name"
            value={filter.search}
            onChange={handleFilterChange}
          />
          <input
            type="text"
            name="poojaId"
            placeholder="Search by Pooja ID"
            value={filter.poojaId}
            onChange={handleFilterChange}
          />
          <select name="category" value={filter.category} onChange={handleFilterChange}>
            <option value="">All Categories</option>
            <option value="1">Category 1</option>
            <option value="2">Category 2</option>
            {/* Add more categories as needed */}
          </select>
          <select name="sort" value={filter.sort} onChange={handleFilterChange}>
            <option value="">Sort By</option>
            <option value="alphabetical">Alphabetical</option>
            <option value="newest">Newest</option>
          </select>
        </div>

        <div className="table-container">
          <table className="pooja-table">
            <thead>
              <tr>
                <th>SR. No</th>
                <th>Pooja Name</th>
                <th>Pooja ID</th>
                <th>Price Tiers</th>
                <th>Description</th>
                <th>Image</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {filteredPoojas.map((pooja, index) => (
                <tr key={pooja.id}>
                  <td>{index + 1}</td>
                  <td>{pooja.pooja_name}</td>
                  <td>{pooja.id}</td>
                  <td>
                    {pooja.price_tiers.length > 0 ? (
                      <ul>
                        {pooja.price_tiers.map((tier) => (
                          <li key={tier.id}>
                            {tier.tier_name}: ₹{tier.price}
                          </li>
                        ))}
                      </ul>
                    ) : (
                      <p>No price tiers available</p>
                    )}
                  </td>
                  <td onClick={() => toggleDescription(pooja.id)} style={{ cursor: 'pointer' }}>
                    {expandedDescriptions[pooja.id] ? pooja.message : `${pooja.message.substring(0, 30)}...`}
                  </td>
                  <td>
                    <img src={`https://free.jeevansanskriti.com/images/${pooja.image}`} alt={pooja.pooja_name} width="50" height="50" />
                  </td>
                  <td>
                    <button className='e-bt' onClick={() => handleEdit(pooja)}>Edit</button>
                    <button className='d-bt' onClick={() => handleDelete(pooja.id)}>Delete</button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      {editPooja && (
  <div className="edit-modal">
    <div className="edit-modal-content">
      <h2>Edit Pooja</h2>
      <form onSubmit={handleUpdate}>
        <label>
          Pooja Name:
          <input
            type="text"
            value={editPooja.pooja_name}
            onChange={(e) => setEditPooja({ ...editPooja, pooja_name: e.target.value })}
          />
        </label>
        <label>
          Price Tiers:
          {editPooja.price_tiers && editPooja.price_tiers.map((tier, index) => (
            <div key={tier.id}>
              <label>
                Tier Name:
                <input
                  type="text"
                  value={tier.tier_name}
                  onChange={(e) => {
                    const updatedTiers = editPooja.price_tiers.map((t, i) =>
                      i === index ? { ...t, tier_name: e.target.value } : t
                    );
                    setEditPooja({ ...editPooja, price_tiers: updatedTiers });
                  }}
                />
              </label>
              <label>
                Price:
                <input
                  type="number"
                  value={tier.price}
                  onChange={(e) => {
                    const updatedTiers = editPooja.price_tiers.map((t, i) =>
                      i === index ? { ...t, price: e.target.value } : t
                    );
                    setEditPooja({ ...editPooja, price_tiers: updatedTiers });
                  }}
                />
              </label>
            </div>
          ))}
        </label>
        <label>
          Description:
          <textarea
            value={editPooja.message}
            onChange={(e) => setEditPooja({ ...editPooja, message: e.target.value })}
          />
        </label>
        <button type="submit">Save</button>
        <button type="button" onClick={() => setEditPooja(null)}>Cancel</button>
      </form>
    </div>
  </div>
)}

    </div>
  );
};

export default AdminAllPoojas;
