import React, { useEffect, useState } from 'react';
import '../Css/PopularPuja.css';
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';

export default function PopularPuja() {
  const [pooja, setPooja] = useState([]);
  const location = useLocation();
  const navigate = useNavigate();

  const fetchPooja = async () => {
    try {
      const { data } = await axios.get('https://free.jeevansanskriti.com/api/v1/pooja');
      const arrayOfpooja = data.data;
      setPooja(
        arrayOfpooja.map((pooja) => {
          const starterTier = pooja.price_tiers.find(tier => tier.tier_name === "Starter");
          const startingPrice = starterTier ? starterTier.price : "Price not available";
          return {
            id: pooja.id,
            image: pooja.image,
            message: pooja.message,
            pooja_name: pooja.pooja_name,
            starting_price: startingPrice
          };
        })
      );
    } catch (error) {
      console.error("Failed to fetch pooja:", error);
    }
  };

  const toPoojaBookingPage = (id) => {
    window.open(`booking/${id}`);
  };

  useEffect(() => {
    fetchPooja();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <div className="popular-puja-container">
      <h2 className="popular-puja-title">Popular Puja</h2>
      <div className="popular-puja-grid">
        {pooja.slice(0, 4).map((pooj) => (
          <div key={pooj.id} className="puja-card" onClick={() => toPoojaBookingPage(pooj.id)}>
            <img src={`https://free.jeevansanskriti.com/images/${pooj.image}`} alt={pooj.pooja_name} />
            <div className="puja-card-content">
              <h3 className="puja-card-title">{pooj.pooja_name}</h3>
              {pooj.starting_price === "Price not available" ? <p>Click me and Worship begins here</p> : <p className="puja-card-message">Starting from {pooj.starting_price}</p>}
            </div>
          </div>
        ))}
      </div>
      <button className="view-more-button" onClick={() => navigate('/store')}>View More</button>
    </div>
  );
}
