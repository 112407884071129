import React from 'react';
import { FaShippingFast, FaCreditCard, FaHeadset, FaUndo } from 'react-icons/fa';
import '../Css/WhyChooseUs.css';
import { LiaShippingFastSolid } from "react-icons/lia";
import { TbTruckReturn } from "react-icons/tb";

const WhyChooseUs = () => {
  return (
    <div className="WY-why-choose-us">
      <h2 className="WY-section-title">
        <span className="WY-highlight">FUNFACT</span>
        Why You Need To Choose Us
      </h2>
      <div className="WY-features">
        <div className="WY-feature-item">
          <LiaShippingFastSolid size={30} />
          <h3>Free Shipping</h3>
          <p>Order Over Rs.500</p>
        </div>
        <div className="WY-feature-item">
          <FaCreditCard size={30} />
          <h3>Quick Payment</h3>
          <p>100% Secure Payment</p>
        </div>
        <div className="WY-feature-item">
          <FaHeadset size={30} />
          <h3>24/7 Support</h3>
          <p>Ready For You</p>
        </div>
        <div className="WY-feature-item">
          <TbTruckReturn size={30} />
          <h3>Easy Returns</h3>
          <p>Exchange/Returns 7 Days</p>
        </div>
      </div>
    </div>
  );
};

export default WhyChooseUs;
