import React, { useEffect, useState } from 'react';
import axios from 'axios';
import SideNav from './SideNav'; // Adjust the import path based on your project structure
import '../Css/AdminContactUs.css'; // Ensure you create this CSS file to style the table

export default function AdminContactUs() {
    const [contacts, setContacts] = useState([]);
    const [filter, setFilter] = useState({ name: '', email: '' });

    const fetchAllContacts = async () => {
        try {
            const { data } = await axios.get('https://free.jeevansanskriti.com/api/v1/contact_us', {
                params: {
                    name: filter.name || undefined,
                    email: filter.email || undefined
                }
            });
            const arrayOfContacts = data.data;
            setContacts(arrayOfContacts.map((contact) => ({
                id: contact.id,
                name: contact.name,
                email: contact.email,
                mobile: contact.mobile,
                message: contact.message,
                created_at: contact.created_at,
                updated_at: contact.updated_at
            })));
        } catch (error) {
            console.error('Error fetching contacts:', error);
        }
    };

    useEffect(() => {
        fetchAllContacts();
    }, [filter]);

    const handleFilterChange = (e) => {
        setFilter({ ...filter, [e.target.name]: e.target.value });
    };

    const formatDate = (dateString) => {
        if (!dateString) return 'N/A';
        const options = { year: 'numeric', month: 'short', day: 'numeric' };
        return new Date(dateString).toLocaleDateString(undefined, options);
    };

    return (
        <>
            <div className="admin-panel-container">
                <SideNav />
                <div className="content-container">
                    <h1 className="page-title">Contact Us Messages</h1>

                    <div className="filter-container">
                        <input
                            type="text"
                            name="name"
                            value={filter.name}
                            onChange={handleFilterChange}
                            placeholder="Search by Name"
                        />
                        <input
                            type="text"
                            name="email"
                            value={filter.email}
                            onChange={handleFilterChange}
                            placeholder="Search by Email"
                        />
                        <button onClick={fetchAllContacts}>Apply Filters</button>
                    </div>

                    <div className="table-container">
                        <table className="contact-table">
                            <thead>
                                <tr>
                                    <th>SR. No</th>
                                    <th>Name</th>
                                    <th>Email</th>
                                    <th>Phone</th>
                                    <th>Message</th>
                                    <th>Created At</th>
                                    <th>Updated At</th>
                                </tr>
                            </thead>
                            <tbody>
                                {contacts.map((contact, index) => (
                                    <tr key={contact.id}>
                                        <td>{index + 1}</td>
                                        <td>{contact.name}</td>
                                        <td>{contact.email}</td>
                                        <td>{contact.mobile}</td>
                                        <td>{contact.message}</td>
                                        <td>{formatDate(contact.created_at)}</td>
                                        <td>{contact.updated_at ? formatDate(contact.updated_at) : 'N/A'}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>
    );
}
