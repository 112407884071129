import React, { useState } from "react";
import SideNav from "./SideNav";
import axios from "axios";

export default function AdminPooja() {
  const [pooja, setPooja] = useState({
    pooja_name: "",
    image: null,
    message: "",
    features: "",
    category_id: "",
  });

  const handleCategorySubmit = async (e) => {
    e.preventDefault();
    try {
      const formData = new FormData();
      formData.append("pooja_name", pooja.pooja_name);
      formData.append("image", pooja.image);
      formData.append("message", pooja.message);
      formData.append("features", pooja.features);
      formData.append("category_id", pooja.category_id);
      const response = await axios.post(
        "https://free.jeevansanskriti.com/api/v1/pooja",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
    } catch (error) {
      if (error.response) {
        // The request was made and the server responded with a status code that falls out of the range of 2xx
        console.error("Error response:", error.response.data);
      } else if (error.request) {
        // The request was made but no response was received
        console.error("Error request:", error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.error("Error message:", error.message);
      }
      console.error("Error config:", error.config);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setPooja({
      ...pooja,
      [name]: value,
    });
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setPooja({
      ...pooja,
      image: file,
    });
  };

  return (
    <div className="admin-panel-container">
      <SideNav />
      <div className="form-container">
        <form onSubmit={handleCategorySubmit}>
          <label>
            Pooja Name:
            <input
              type="text"
              name="pooja_name"
              value={pooja.pooja_name}
              onChange={handleChange}
              required
            />
          </label>
          <label>
            Pooja Image:
            <input
              type="file"
              name="image"
              onChange={handleFileChange}
              required
            />
          </label>
          <label>
            Message:
            <input
              type="text"
              name="message"
              value={pooja.message}
              onChange={handleChange}
              required
            />
          </label>
          <label>
            Features (for multiple features, separate with ","):
            <input
              type="text"
              name="features"
              value={pooja.features}
              onChange={handleChange}
              required
            />
          </label>
          <label>
            Category name:
            <input
              type="text"
              name="category_id"
              value={pooja.category_id}
              onChange={handleChange}
              required
            />
          </label>
          <button type="submit">Upload Pooja</button>
        </form>
      </div>
    </div>
  );
}
