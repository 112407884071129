import React, { useState } from 'react';
import SideNav from './SideNav';
import axios from 'axios';

export default function AdminFeatures() {
  const [feature, setFeature] = useState({
    product_id: '',
    featureName: '',
    featureValue: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFeature({
      ...feature,
      [name]: value,
    });
  };

  const handleFeatureSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        'https://free.jeevansanskriti.com/api/v1/product_features',
        feature,
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
    } catch (error) {
      if (error.response) {
        console.error('Error response:', error.response.data);
      } else if (error.request) {
        console.error('Error request:', error.request);
      } else {
        console.error('Error message:', error.message);
      }
      console.error('Error config:', error.config);
    }
  };

  return (
    <div className="admin-panel-container">
      <SideNav />
      <div className="form-container">
        <form onSubmit={handleFeatureSubmit}>
          <label>
            Product ID:
            <input
              type="text"
              name="product_id"
              value={feature.product_id}
              onChange={handleChange}
              required
            />
          </label>
          <label>
            Feature Name:
            <input
              type="text"
              name="featureName"
              value={feature.featureName}
              onChange={handleChange}
              required
            />
          </label>
          <label>
            Feature Value:
            <input
              type="text"
              name="featureValue"
              value={feature.featureValue}
              onChange={handleChange}
              required
            />
          </label>
          <button type="submit">Add Feature</button>
        </form>
      </div>
    </div>
  );
}