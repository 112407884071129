import React, { useState } from "react";
import SideNav from "./SideNav";
import axios from "axios";
import "../Css/AdminPanel.css";

export default function AdminPanel() {
  const [product, setProduct] = useState({
    product_name: "",
    main_image: null,
    product_description: "",
    product_short_description: "",
    subcategory_id: "",
    price: "",
    stock_quantity: "",
  });

  const [file, setFile] = useState(null);
  const [discount, setDiscount] = useState(0);
  const [discountedPrice, setDiscountedPrice] = useState(0);

  const handleProductSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('main_image', file);
    for (let key in product) {
      formData.append(key, product[key]);
    }
    formData.append('discount', discount);
    formData.append('discounted_price', discountedPrice);

    try {
      const response = await axios.post(
        "https://free.jeevansanskriti.com/api/v1/product",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      console.log("Product created:", response.data);
    } catch (error) {
      if (error.response) {
        console.error("Error response:", error.response.data);
      } else if (error.request) {
        console.error("Error request:", error.request);
      } else {
        console.error("Error message:", error.message);
      }
      console.error("Error config:", error.config);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setProduct({
      ...product,
      [name]: value,
    });
    if (name === "price") {
      calculateDiscountedPrice(value, discount);
    }
  };

  const handleDiscountChange = (e) => {
    const value = e.target.value;
    setDiscount(value);
    calculateDiscountedPrice(product.price, value);
  };

  const calculateDiscountedPrice = (price, discount) => {
    const discountValue = (price * discount) / 100;
    const finalPrice = price - discountValue;
    setDiscountedPrice(finalPrice);
  };

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  return (
    <>
      <div className="admin-panel-container">
        <SideNav />
        <div className="form-container">
          <form onSubmit={handleProductSubmit}>
            <label>
              Product Name:
              <input
                type="text"
                name="product_name"
                value={product.product_name}
                onChange={handleChange}
                required
              />
            </label>
            <label>
              Product Description:
              <textarea
                name="product_description"
                value={product.product_description}
                onChange={handleChange}
                required
              />
            </label>
            <label>
              Product Short Description:
              <input
                type="text"
                name="product_short_description"
                value={product.product_short_description}
                onChange={handleChange}
                required
              />
            </label>
            <label>
              Subcategory ID:
              <input
                type="text"
                name="subcategory_id"
                value={product.subcategory_id}
                onChange={handleChange}
                required
              />
            </label>
            <label>
              Price:
              <input
                type="number"
                name="price"
                value={product.price}
                onChange={handleChange}
                required
              />
            </label>
            <label>
              Discount (%):
              <input
                type="number"
                name="discount"
                value={discount}
                onChange={handleDiscountChange}
              />
            </label>
            <label>
              Discounted Price:
              <input
                type="number"
                name="discounted_price"
                value={discountedPrice}
                readOnly
              />
            </label>
            <label>
              Stock Quantity:
              <input
                type="number"
                name="stock_quantity"
                value={product.stock_quantity}
                onChange={handleChange}
                required
              />
            </label>
            <label>
              Product Image:
              <input
                type="file"
                name="main_image"
                onChange={handleFileChange}
                required
              />
            </label>
            <button type="submit">Create Product</button>
          </form>
        </div>
      </div>
    </>
  );
}
