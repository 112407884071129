import React, { useState } from "react";
import SideNav from "./SideNav";
import axios from "axios";

export default function AdminPoojaPriceTier() {
  const [priceTier, setPriceTier] = useState({
    pooja_id: "",
    tier_name: "",
    price: "",
    features: "",
  });

  const handlePriceTierSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        "https://free.jeevansanskriti.com/api/v1/price_tier",
        priceTier,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
    } catch (error) {
      if (error.response) {
        // The request was made and the server responded with a status code that falls out of the range of 2xx
        console.error("Error response:", error.response.data);
      } else if (error.request) {
        // The request was made but no response was received
        console.error("Error request:", error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.error("Error message:", error.message);
      }
      console.error("Error config:", error.config);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setPriceTier({
      ...priceTier,
      [name]: value,
    });
  };

  return (
    <div className="admin-panel-container">
      <SideNav />
      <div className="form-container">
        <form onSubmit={handlePriceTierSubmit}>
          <label>
            Pooja ID:
            <input
              type="text"
              name="pooja_id"
              value={priceTier.pooja_id}
              onChange={handleChange}
              required
            />
          </label>
          <label>
            Tier Name:
            <input
              type="text"
              name="tier_name"
              value={priceTier.tier_name}
              onChange={handleChange}
              required
            />
          </label>
          <label>
            Price:
            <input
              type="number"
              name="price"
              value={priceTier.price}
              onChange={handleChange}
              required
            />
          </label>
          <label>
            Features (for multiple features, separate with ","):
            <input
              type="text"
              name="features"
              value={priceTier.features}
              onChange={handleChange}
              required
            />
          </label>
          <button type="submit">Create Price Tier</button>
        </form>
      </div>
    </div>
  );
}
