import React from 'react';
import { useLocation } from 'react-router-dom';
import '../Css/OrderConfirmation.css';
import Header from './Header';

const OrderConfirmation = () => {
  const location = useLocation();
  const { orderData, productDetails, result } = location.state || {};

  return (
    <>
      <Header />
      <div className="ord-container">
        <h1 className="ord-header">Thank you for shopping with us.</h1>
        <p className="ord-subheader">Your account has been charged and your transaction is successful. We will be processing your order soon.</p>
        <div className="ord-details">
          <div className="ord-info">
            <p><strong>ORDER NUMBER:</strong> {result?.orderNumber}</p>
            <p><strong>DATE:</strong> {new Date().toLocaleDateString()}</p>
            <p><strong>EMAIL:</strong> {orderData.billingInfo.email}</p>
            <p><strong>TOTAL:</strong> ₹{orderData.amount}</p>
            <p><strong>PAYMENT METHOD:</strong> {orderData.payment_mode}</p>
          </div>
          <p className="ord-pay-method">{orderData.payment_mode === 'COD' ? 'Pay with cash upon delivery.' : 'Paid via Razorpay.'}</p>
          <h2 className="ord-section-header">Order details</h2>
          <table className="ord-table">
            <thead>
              <tr>
                <th>Product</th>
                <th>Total</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{productDetails.productName} × {productDetails.quantity}</td>
                <td>₹{productDetails.price}</td>
              </tr>
            </tbody>
          </table>
          <p className="ord-subtotal"><strong>Subtotal:</strong> ₹{productDetails.price}</p>
          <p className="ord-shipping"><strong>Shipping:</strong> ₹50.00 via Flat rate</p>
          <p className="ord-total"><strong>Total:</strong> ₹{orderData.amount}</p>
          <h2 className="ord-section-header">Billing address</h2>
          <address className="ord-address">
            {orderData.billingInfo.firstName} {orderData.billingInfo.lastName}<br />
            {orderData.billingInfo.address}<br />
            {orderData.billingInfo.city} {orderData.billingInfo.pincode}<br />
            {orderData.billingInfo.state}<br />
            {orderData.billingInfo.phone}<br />
            {orderData.billingInfo.email}
          </address>
          <h2 className="ord-section-header">Shipping address</h2>
          <address className="ord-address">
            {orderData.billingInfo.firstName} {orderData.billingInfo.lastName}<br />
            {orderData.billingInfo.address}<br />
            {orderData.billingInfo.city} {orderData.billingInfo.pincode}<br />
            {orderData.billingInfo.state}
          </address>
        </div>
      </div>
    </>
  );
};

export default OrderConfirmation;
