import React, { useState } from 'react';
import '../Css/PopupForm.css';

const PopupForm = ({ onClose }) => {
  const [formData, setFormData] = useState({
    name: '',
    contact: '',
    email: '',
    enquiry_about: [],
    message: ''
  });

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    if (type === 'checkbox') {
      setFormData((prevData) => ({
        ...prevData,
        enquiry_about: checked
          ? [...prevData.enquiry_about, value]
          : prevData.enquiry_about.filter((item) => item !== value)
      }));
    } else {
      setFormData({
        ...formData,
        [name]: value
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch('https://free.jeevansanskriti.com/api/v1/enquiry_form', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          name: formData.name,
          email: formData.email,
          enquiry_about: formData.enquiry_about.join(', '),
          mobile: formData.contact,
          message: formData.message
        })
      });

      const result = await response.json();
      if (response.ok) {
        alert('Enquiry form submitted successfully');
        onClose();
      } else {
        alert(`Error: ${result.message}`);
      }
    } catch (error) {
      alert('An error occurred. Please try again later.');
      console.error(error);
    }
  };

  return (
    <div className="popup-container">
      <div className="popup-content">
        <h2 className="popup-title">Enquiry Form</h2>
        <form onSubmit={handleSubmit}>
          <input
            type="text"
            className="popup-input-name"
            name="name"
            placeholder="Full name"
            value={formData.name}
            onChange={handleInputChange}
            required
          />
          <input
            type="text"
            className="popup-input-contact"
            name="contact"
            placeholder="Contact Number"
            value={formData.contact}
            onChange={handleInputChange}
            required
          />
          <input
            type="email"
            className="popup-input-email"
            name="email"
            placeholder="Email"
            value={formData.email}
            onChange={handleInputChange}
            required
          />
          <div className="popup-enquiry-options">
            <label>
              <input
                type="checkbox"
                className="popup-checkbox-vedic"
                name="enquiry_about"
                value="Vedic Puja"
                onChange={handleInputChange}
              />
              Vedic Puja
            </label>
            <label>
              <input
                type="checkbox"
                className="popup-checkbox-astrology"
                name="enquiry_about"
                value="Astrology"
                onChange={handleInputChange}
              />
              Astrology
            </label>
            <label>
              <input
                type="checkbox"
                className="popup-checkbox-astrology"
                name="enquiry_about"
                value="Catering"
                onChange={handleInputChange}
              />
              Catering
            </label>
            <label>
              <input
                type="checkbox"
                className="popup-checkbox-astrology"
                name="enquiry_about"
                value="Astrology"
                onChange={handleInputChange}
              />
              Samagri
            </label>
          </div>
          <textarea
            className="popup-textarea-enquiry"
            name="message"
            placeholder="Write your enquiry here..."
            value={formData.message}
            onChange={handleInputChange}
            required
          ></textarea>
          <div className="popup-buttons">
            <button type="button" className="popup-button-close" onClick={onClose}>
              CLOSE
            </button>
            <button type="submit" className="popup-button-submit">
              SUBMIT
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default PopupForm;
