import React from "react";
import "../Css/SideNav.css";
import { CiShoppingCart } from "react-icons/ci";
import { TbCategory2 } from "react-icons/tb";
import { BiCategoryAlt } from "react-icons/bi";
import { TbArrowsExchange2 } from "react-icons/tb";
import { TbHotelService } from "react-icons/tb";
import { Link } from "react-router-dom";
import { HiOutlineUsers } from "react-icons/hi2";
import { BsBoxSeam } from "react-icons/bs";
import { TbBrandGoogleBigQuery } from "react-icons/tb";
import { GiPartyFlags } from "react-icons/gi";
import { MdOutlineContentPasteSearch } from "react-icons/md";
import { MdOutlineInventory } from "react-icons/md";
import { FaBloggerB } from "react-icons/fa6";
import { IoImageOutline } from "react-icons/io5";
import { GiGreekTemple } from "react-icons/gi";
import { TiEdit } from "react-icons/ti";
import { IoPricetagsOutline } from "react-icons/io5";


export default function SideNav() {
  return (
    <div className="AdminNav-nav-bar">
      <input
        type="checkbox"
        id="AdminNav-nav-toggle"
        className="AdminNav-nav-toggle"
      />
      <div className="AdminNav-nav-header">
        <a href="#" target="_blank" className="AdminNav-nav-title">
          JIVANSANSKRITI
        </a>
        <label
          htmlFor="AdminNav-nav-toggle"
          className="AdminNav-nav-toggle-label"
        >
          <span className="AdminNav-nav-toggle-burger"></span>
        </label>
        <hr />
      </div>
      <div className="AdminNav-nav-content">
        <Link to="/admin-panel">
          <div className="AdminNav-nav-button">
            <CiShoppingCart className="fas AdminNav-nav-button-icon" />
            <span>Product</span>
          </div>
        </Link>
        <Link to="/admin-product-images">
          <div className="AdminNav-nav-button">
            <IoImageOutline className="fas AdminNav-nav-button-icon" />
            <span>Product Images</span>
          </div>
        </Link>
        <Link to="/admin-pooja">
          <div className="AdminNav-nav-button">
            <GiGreekTemple className="fas AdminNav-nav-button-icon" />
            <span>Puja</span>
          </div>
        </Link>
    <Link to="/admin-pooja-features">
          <div className="AdminNav-nav-button">
            <TiEdit className="fas AdminNav-nav-button-icon" />
            <span>Puja Features</span>
          </div>
        </Link>
        <Link to="/admin-pooja-price">
          <div className="AdminNav-nav-button">
            <IoPricetagsOutline className="fas AdminNav-nav-button-icon" />
            <span>Puja Price Tier</span>
          </div>
        </Link>
        
          <Link to="/admin/all_product">
            <div className="AdminNav-nav-button">
              <MdOutlineInventory className="fas AdminNav-nav-button-icon" />
              <span>All Product</span>
            </div>
          </Link>

          <Link to="/admin/all_puja">
            <div className="AdminNav-nav-button">
              <MdOutlineInventory className="fas AdminNav-nav-button-icon" />
              <span>All Puja</span>
            </div>
          </Link>
        

       
          <Link to="/admin-blog">
            <div className="AdminNav-nav-button">
              <FaBloggerB className="fas AdminNav-nav-button-icon" />
              <span>Blogs</span>
            </div>
          </Link>
       

        <Link to="/admin/categories">
          <div className="AdminNav-nav-button">
            <TbCategory2 className="fas AdminNav-nav-button-icon" />
            <span>Categories</span>
          </div>
        </Link>


        {/* <Link to="/admin/subcategories">
          <div className="AdminNav-nav-button">
            <BiCategoryAlt className="fas AdminNav-nav-button-icon" />
            <span>Subcategories</span>
          </div>
        </Link> */}

        <Link to="/admin/orders">
          <div className="AdminNav-nav-button">
            <TbArrowsExchange2 className="fas AdminNav-nav-button-icon" />
            <span>Orders</span>
          </div>
        </Link>

        <Link to="/admin/contact">
          <div className="AdminNav-nav-button">
            <TbBrandGoogleBigQuery className="fas AdminNav-nav-button-icon" />
            <span>Enquiry</span>
          </div>
        </Link>

        <Link to="/admin/service-enquiry">
          <div className="AdminNav-nav-button">
            <MdOutlineContentPasteSearch className="fas AdminNav-nav-button-icon" />
            <span>Catering Enquiries</span>
          </div>
        </Link>

        <Link to="/admin/Users">
          <div className="AdminNav-nav-button">
            <HiOutlineUsers className="fas AdminNav-nav-button-icon" />
            <span>Users</span>
          </div>
        </Link>

        <Link to="/admin/Banners">
          <div className="AdminNav-nav-button">
            <GiPartyFlags className="fas AdminNav-nav-button-icon" />
            <span>Banners</span>
          </div>
        </Link>

        <div className="AdminNav-nav-content-highlight"></div>
      </div>
      <input
        type="checkbox"
        id="AdminNav-nav-footer-toggle"
        className="AdminNav-nav-footer-toggle"
      />
      <div className="AdminNav-nav-footer">
        <div className="AdminNav-nav-footer-heading">
          <div className="AdminNav-nav-footer-avatar">
            {/* <img src="../assets/shapelogo.png" alt="avatar" /> */}
          </div>
          <div className="AdminNav-nav-footer-titlebox">
            <a href="#" target="_blank" className="AdminNav-nav-footer-title">
              JIVANSANSKRITI
            </a>
            <span className="AdminNav-nav-footer-subtitle">Admin</span>
          </div>
          <label
            htmlFor="AdminNav-nav-footer-toggle"
            className="AdminNav-nav-footer-toggle-label"
          >
            <i className="fas fa-caret-up"></i>
          </label>
        </div>
        <div className="AdminNav-nav-footer-content">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua.
        </div>
      </div>
    </div>
  );
}
