import React from 'react';
import '../Css/AboutSection.css';
import Lottie from 'lottie-react';
import animationData from '../animations/pujaAnimation.json'; // Make sure to replace with your actual Lottie animation file path

export default function AboutSection() {
  return (
    <div className="RT-about-section">
      <div className="RT-about-text">
        <h2 className="RT-about-heading">About Our Puja Services</h2>
        <p className="RT-about-paragraph">
          We offer a variety of puja services to help you connect with your spirituality and traditions. Our experienced priests and beautifully crafted ceremonies ensure a memorable and divine experience for you and your family.
        </p>
      </div>
      <div className="RT-about-animation">
        <Lottie animationData={animationData} />
      </div>
    </div>
  );
}
